<template>
	<div class="page xieyi_page">
		<div class="block30"></div>
		<div class="w1200 xieyi_page_box">
			<div class="conbox" v-html="actObj.contents"></div>
		</div>
		<div class="block30"></div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			actObj:{},//协议内容
		};
	},
	mounted: function() {
		this.getpageArticle()
	},
	methods: {
		getpageArticle() {
			var _this = this
			var params = {
				typeId:1,//协议
				currentPage:1,
				pageSize:1,
			}
			this.$http.post('app/article/pageArticle', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records
					if(records.length>0){
						_this.actObj = records[0]
					}else{
						
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		
		
	}
};
</script>
<style lang="scss" scoped>
	.xieyi_page{
		width: 100%;
		background: #F7F8FB;
		.xieyi_page_box{
			border-radius: 12px;
			background: #FFF;
			box-shadow: 0px 4px 7px 0px rgba(187, 200, 236, 0.25);
			padding: 32px;
			box-sizing: border-box;
		}
		.conbox{}
	}
</style>
